<template>
  <div
    v-if="!$store.getters['common/isLoading']"
    class="d-flex flex-column"
  >
    <div class="d-flex flex-column flex-sm-row mb-1">
      <div class="d-flex flex-column mb-8 mb-sm-0">
        <h5 class="h5-text mb-5">{{isNewRequest ? `Новый запрос на лизинг` : `Запрос на лизинг № ${request.id}`}} </h5>
      </div>
    </div>
    <div class="d-flex flex-column">
        <div class="page-block flex-column">
          <div class="subhead-text mb-8">Информация о компании клиента</div>
            <v-row v-if="isNewRequest">
              <v-col cols="12" sm="12" md="12">
                <v-radio-group v-model="request.buyer_type_id" row>
                  <v-radio
                    v-for="buyerType in buyerTypes"
                    :key="buyerType.id"
                    :label="buyerType.name"
                    :value="buyerType.id"
                    @change="changeDataAction"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col  cols="12" sm="12" md="6">
                <p class="label">{{buyerTypes.find(type => type.id == request.buyer_type_id).name}}</p>
              </v-col>
            </v-row>
          <v-row v-if="isNewRequest">
            <v-col cols="12" sm="12" md="6">
                <p class="label">Тип предмета лизинга</p>
                <v-radio-group v-model="request.subject_type_id" row>
                    <v-radio
                      v-for="subjectType in subjectTypes"
                      :key="subjectType.id"
                      :label="subjectType.name"
                      :value="subjectType.id"
                      @change="changeDataAction"
                    ></v-radio>
                  </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-else>
              <v-col  cols="12" sm="12" md="6">
                <p class="label">Тип предмета лизинга</p>
                <p class="p-content">{{subjectTypes.find(subject => subject.id == request.subject_type_id).name}}</p>
              </v-col>
          </v-row>
          <v-row v-if="request.buyer_type_id === 1">
            <v-col cols="12" sm="12" md="6">
               <v-autocomplete
                  v-if="isNewRequest"
                  v-model="suggest"
                  :items="suggestions"
                  :search-input.sync="search"
                  class="lightgrey"
                  clearable
                  hide-details="auto"
                  hide-no-data
                  label="ИНН или название компании"
                  loading="suggestLoading"
                  outlined
                  return-object
                  @input="setCompany"
              >
                  <template slot="item" slot-scope="data">
                      ({{ data.item.inn }}) {{ data.item.name.full_with_opf }}
                  </template>
                  <template #selection="data">
                      ({{ data.item.inn }}) {{ data.item.name.full_with_opf }}
                  </template>
              </v-autocomplete>
                <div v-else>
                  <p class="label">Наименование</p>
                  <p class="p-content">{{request.company_name}}</p>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
               <v-text-field
                  v-if="isNewRequest"
                  v-model="request.company_address"
                  label="Адрес компании"
                  @blur="getDadataAddress"
                  outlined
                ></v-text-field>
                <div v-else>
                  <p class="label">Адрес компании</p>
                  <p class="p-content">{{request.company_address}}</p>
                </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-if="isNewRequest"
                v-model="request.client_name"
                label="Ф.И.О"
                outlined
              ></v-text-field>
              <div v-else>
                  <p class="label">Ф.И.О Клиента</p>
                  <p class="p-content">{{request.client_name}}</p>
                </div>
            </v-col>
          </v-row>
          <div class="subhead-text mb-8">Информация о транспортном средстве</div>
          <v-row>
            <v-col cols="12" sm="12" md="3">
                <v-autocomplete
                    v-model="request.car_brand_id"
                    v-if="isNewRequest"
                    :items="carBrands"
                    class="lightgrey"
                    hide-details="auto"
                    item-text="name"
                    item-value="id"
                    label="Марка автомобиля"
                    outlined
                    @change="getCarModels"
                ></v-autocomplete>
                <div v-else>
                  <p class="label">Марка</p>
                  <p class="p-content">{{carBrands.find(car => car.id == request.car_brand_id).name}}</p>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-autocomplete
                  v-model="request.car_model_id"
                  v-if="isNewRequest"
                  :items="carModels"
                  class="lightgrey"
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  label="Модель автомобиля"
                  outlined
                ></v-autocomplete>
                <div v-else>
                  <p class="label">Модель</p>
                  <p class="p-content">{{request.car_model.name}}</p>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3">
               <v-text-field
                  v-if="isNewRequest"
                  v-model="request.price"
                  label="Стоимость предмета лизинга"
                  type="number"
                  outlined
                ></v-text-field>
                <div v-else>
                  <p class="label">Стоимость предмета лизинга</p>
                  <p class="p-content">{{request.price}} руб.</p>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="3">
               <v-text-field
                  v-if="isNewRequest"
                  v-model="request.prepaid"
                  label="Аванс, %"
                  type="number"
                  max="100"
                  outlined
                ></v-text-field>
                <div v-else>
                  <p class="label">Аванс (%)</p>
                  <p class="p-content">{{request.prepaid}}</p>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="3">
               <v-text-field
                  v-if="isNewRequest"
                  v-model="request.period"
                  label="Срок лизинга, мес."
                  type="number"
                  outlined
                  max="124"
                ></v-text-field>
                <div v-else>
                  <p class="label">Срок лизинга</p>
                  <p class="p-content">{{request.period}}</p>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-if="isNewRequest"
                  v-model="request.comment"
                  height="60px"
                  label="Комплектация, цвет, АВ и прочее"
                  outlined
                ></v-textarea>
                <div v-else>
                  <p class="label">Комментарий</p>
                  <p class="p-content">{{request.comment}}</p>
                </div>
            </v-col>
          </v-row>
          <v-btn v-if="isNewRequest" width="170" :disabled="!selectedCompanyCount" large color="primary" elevation="0" class="mb-2" @click="sendRequest">
            {{`Отправить`}}
          </v-btn>
        </div>
    </div>

    <div class="d-flex flex-column">
      <h5 class="h5-text mb-7 mt-7">{{isNewRequest ? 'У кого запрашиваем ?' : 'Лизинговые компании'}}</h5>
      <div v-if="isNewRequest" class="flex-column lc-list-wrapper new-request mb-8">

        <v-row class="mb-2" v-if="leasungCompanies.length" style="padding-left: 25px">
          <v-col cols="12" sm="12" md="1">
            <label class="custom-checkbox">
              <input type="checkbox" v-model="selectAllLeasingCompanies" @change="toggleAllCompanies" /><span></span>
            </label>
          </v-col>
          <v-col cols="12" sm="12" md="6">Выбрать все ({{leasungCompanies.length}})</v-col>
        </v-row>

        <div v-if="leasungCompanies.length">
          <v-row class="lc-list-item mb-2 page-block" v-for="company in leasungCompanies" :key="company.id">
            <v-col cols="12" sm="12" md="1">
              <label class="custom-checkbox">
                <input type="checkbox" :value="company.id" v-model="selectedCompanies" /><span></span>
              </label>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="lc-list-item__name">{{company.name}}</v-col>
          </v-row>
        </div>

        <div v-else>
          <p>По указанным параметрам не найдено лизинговых компаний</p>
        </div>

      </div>
      <div v-else class="flex-column lc-list-wrapper mb-8">
        <v-row class="lc-list-item page-block  mb-2" v-for="order in request.offers" :key="order.id">
          <v-col cols="12" sm="12" md="2">
            <p class="label">Лизинговая компания</p>
            <div>{{leasungCompanies.find(company => company.id == order.leasing_company_id).name }}</div>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <p class="label">Стоимость с лизингом</p>
            <div>{{order.final_price == 0 ? 'Ожидается' : order.final_price}}</div>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <p class="label">Комментарий</p>
            <div>{{order.comment}}</div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <p class="label">Предложение</p>
            <div v-if="order.approval_probability">
              <table class="offer-file-table">
                <tr v-for="file in order.files" :key="file.id">
                  <td>
                    <a class="file-link" :href="renderFileLink(file.file_name)" target="blank">{{`${file.file_name.slice(0,16)}...`}}</a>
                    <span class="remove-file" @click="() => { removeFile(file) }">x</span>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p>Ожидается</p>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <p class="label">Вероятность одобрения</p>
            <div class="mt-4" v-if="order.approval_probability">
              <div :class="[`circle`, order.approval_probability == 1 ? `active` : ``]"></div>
              <div :class="[`circle`, order.approval_probability == 2 ? `active` : ``]"></div>
              <div :class="[`circle`, order.approval_probability == 3 ? `active` : ``]"></div>
            </div>
            <div v-else>
              <p>Ожидается</p>
            </div>
          </v-col>
        </v-row>
        <h5 class="h5-text mb-7 mt-7">Отправка КП в другие лизинговые компании
          <span class="toggle-btn" @click="toggleOtherCompanies">
            <v-icon color="blue darken-2">{{showOtherCompanies ? `mdi-chevron-double-up` : `mdi-chevron-double-down`}}</v-icon>
          </span>
        </h5>
        <div v-if="showOtherCompanies">
          <v-row class="lc-list-item page-block  mb-2" v-for="company in leasungCompanies.filter(company => { return !companiesWithOffer.includes(company.id) })" :key="company.id">
            <v-col cols="12" sm="12" md="6" class="lc-list-item__name">{{company.name}}</v-col>
            <v-col cols="12" sm="12" md="6" class="lc-list-item__name">
              <v-btn color="primary" elevation="0" class="mb-2" @click="() => { resendRequest(company.id) }">
                {{`Отправить запрос в ЛК`}}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-btn v-if="isNewRequest" width="170" :disabled="!selectedCompanyCount" large color="primary" elevation="0" class="mb-16" @click="sendRequest">
        {{`Отправить`}}
      </v-btn>
    </div>
  </div>
</template>

<script>
import LeasingRequest from '@/apis/LeasingRequest'
import Partners from '@/apis/Partners'
import Offer from '@/apis/Offers'
import axios from 'axios'
import Brands from '@/apis/Brands'
import { Http } from '../../apis/Http'

export default {
  data() {
    return {
      permission: ['admin', 'dc'],
      request: {
        id: this.$route.params.id ? this.$route.params.id : null
      },
      leasungCompanies: [],
      buyerTypes: [
        {
          name: 'Юр. Лицо или ИП',
          id: 1
        },
        {
          name: 'Физическое лицо',
          id: 2
        }
      ],
      subjectTypes: [
        {
          name: 'Легковой',
          id: 1
        },
        {
          name: 'Грузовой',
          id: 2
        },
        {
          name: 'Б/У',
          id: 3
        },
        {
          name: 'Коммерческие',
          id: 4
        },
        {
          name: 'Спецтехника',
          id: 5
        },
        {
          name: 'Оборудование',
          id: 6
        }
      ],

      selectAllLeasingCompanies: 0,

      search: null,
      suggest: null,
      suggestions: [],
      suggestLoading: false,

      carBrands: [],
      carModels: [],
      requiredFields: ['period', 'prepaid', 'price', 'car_brand_id', 'car_model_id', 'comment'],

      selectedCompanies: [],
      companiesWithOffer: [],
      showOtherCompanies: false
    }
  },
  computed: {
    isNewRequest() {
      return !this.request.id
    },
    selectedCompanyCount() {
      return this.selectedCompanies.length
    }
  },
  mounted() {
    Brands.fetchBrands().then(({ data }) => {
      this.carBrands = data.data
    })
    if (this.request.id) {
      this.getRequestData()
    } else {
      this.clearRequestData()
    }
    this.getLeasingCompanies()
  },
  watch: {
    search(val) {
        if (!val || val.length < 3) return
        this.getCompanySuggestions(val).then(items => {
            if (items) this.suggestions = items
        })
    }
  },
  methods: {
    renderFileLink(filename) {
      return `http://188.225.11.172:8000/api/storage/commercial_proposals/${filename}`
    },
    formValidation() {
      let isValid = true
      this.requiredFields.forEach(field => {
        if (!this.request[field]) isValid = false
      })
      return isValid
    },
    toggleAllCompanies() {
      if (this.selectAllLeasingCompanies) {
        this.selectedCompanies = this.leasungCompanies.map(company => { return company.id })
      } else {
        this.selectedCompanies = []
      }
    },
    async setCompany() {
      if (!this.suggest) {
        return
      }
      this.request.company_inn = this.suggest.inn
      this.request.company_name = this.suggest.name.short
      this.request.company_address = this.suggest.address.value
    },
    async getCompanySuggestions(query) {
        this.suggestLoading = true
        const res = await axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
            { query },
            { headers: { Authorization: 'Token ' + '48fa6545fbbdc746a9c0afa9a5bcdcea66c8327c' } })
            .catch(e => console.log(e))
            .finally(() => { this.suggestLoading = false })

        if (!res?.data?.suggestions) return

        const data = res.data.suggestions.map(item => {
            item.data.text = `${item.data.inn} ${item.data.name.short_with_opf} ${item.data.address.value}`
            item.data.value = item.data.inn
            return item.data
        })
        return data
    },
    getDadataAddress() {
      Partners.getDadataAddress(this.request.company.address).then(res => {
        this.request.company.address = res.data.address
      })
    },
    getRequestData() {
      LeasingRequest.getLeasingRequestById(this.$route.params.id).then(res => {
        this.request = res.data
        this.companiesWithOffer = res.data.offers.map(offer => { return offer.leasing_company_id })
      })
    },
    clearRequestData() {
      this.request = LeasingRequest.newLeasingRequest()
    },
    getLeasingCompanies() {
      Http.post('/leasing-request/companies', {
        buyer_type_id: this.request.buyer_type_id,
        subject_type_id: this.request.subject_type_id
      }).then(res => {
        this.leasungCompanies = res.data
      })
    },
    getCarModels(value) {
      Brands.fetchModelsByBrands(value).then(res => {
        this.carModels = res.data
      })
    },
    renderOrderList() {
      this.request.offers = this.selectedCompanies.map(companyId => {
        return {
          id: null,
          status: 'new',
          bid_id: this.request.id,
          leasing_company_id: companyId,
          final_price: this.request.price,
          approval_probability: 0
        }
      })
    },
    sendRequest() {
      if (!this.formValidation()) {
        this.showSystemNotification('Обязательные поля не заполнены')
        return
      }
      this.renderOrderList()
      if (this.request.offers.length) {
        LeasingRequest.sendRequestToLeasing(this.request).then(res => {
          this.showSystemNotification('Ваше предложение направлено в лизинговые компании')
          this.$router.push('/dc/requests')
        })
      } else {
        this.showSystemNotification('Выберите хотя бы одну лизинговую копанию')
      }
    },
    resendRequest(companyId) {
      const newOffer = {
        bid_id: this.request.id,
        leasing_company_ids: [companyId]
      }

      Offer.create(newOffer).then(res => {
        location.reload()
      })
    },
    changeDataAction() {
      this.getLeasingCompanies()
    },
    removeFile(file) {
      Offer.removeFile(file.id).then(() => {
        location.reload()
      })
    },
    toggleOtherCompanies() {
      this.showOtherCompanies = !this.showOtherCompanies
    }
  }
}
</script>

<style scoped>
  .label {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    color: #494B58;
  }
  .p-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #878686;
  }
  .lc-list-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
  .lc-list-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .lc-list-item .lc-list-item__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }
  .lc-list-item .circle {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: #D9D9D9;
    border-radius: 50%;
    margin-right: 8px;
  }
  .lc-list-item .circle:nth-child(1).active {
    background: #F35555;
  }
  .lc-list-item .circle:nth-child(2).active {
    background: #f3c255;
  }
  .lc-list-item .circle:nth-child(3).active {
    background: #34e74a;
  }
  .lc-list-item .lc-list-item__link {
    border: 1px solid #3A76F0;
    border-radius: 5px;
    padding: 10px 14px;
    text-decoration: none;
  }
  label.custom-checkbox input[type=radio],
  label.custom-checkbox input[type=checkbox] {
    display: none;
  }
  label.custom-checkbox input[type=radio] + span::before,
  label.custom-checkbox input[type=checkbox] + span::before {
    content: ' ';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #F3F3F3;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    margin-right: 6px;
    font-size: 22px;
    text-align: center;
  }
  label.custom-checkbox input[type=radio]:checked + span::before,
  label.custom-checkbox input[type=checkbox]:checked + span::before {
    content: '✔';
    background: #EBF9FF;
    border: 1px solid #3A76F0;
    color: #3A76F0;
  }

  .offer-file-table tr {
    font-size: 14px;
  }

  .offer-file-table td {
    padding: 2px 8px;
  }

  .offer-file-table tr:nth-child(2n){
    background: #f1f1f1;
  }

  .offer-file-table td a {
    text-decoration: none;
  }

  .offer-file-table .remove-file {
    margin-left: 4px;
    cursor: pointer;
  }

  .toggle-btn {
    cursor: pointer;
  }

  @media screen and (max-width: 524px) {
    .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-right: 0px;
      width: 50%;
    }
  }
</style>
