import { Http } from '@/apis/Http'

export default {
    getList() {
        return Http.get('/offer')
    },

    getItem(id) {
        return Http.get(`/offer/${id}`)
    },

    create(offer) {
        return Http.post('/offer/new', offer)
    },

    update(offer) {
        return Http.post('/offer/update', offer)
    },

    remove(offer) {
        return Http.post('/offer/remove', offer)
    },

    uploadFile(form) {
        return Http.post('/offer/upload-file', form)
    },

    removeFile(fileId) {
        return Http.post('/offer/remove-file', {
            file_id: fileId
        })
    }
}
