import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  fetchBrands(brandId: number) {
    return Http.get('/admin/get-brands', {
      /* params: {
        brandId: brandId
      } */
    })
  },

  fetchDetailsForUpdateCarBrand(carBrandId: number) {
    return Http.get(`/admin/fetch-details-for-update-car-brand/${carBrandId}`)
  },

  updateBrand(carBrandId: number, detailBrand: any) {
    return Http.put(`/admin/brands/${carBrandId}`, detailBrand)
  },

  storeBrand(detailBrand: any) {
    return Http.post('/admin/brands/add', detailBrand)
  },

  storeModel(carBrandId: number, detailModel: any) {
    return Http.post(`/admin/brands/${carBrandId}/models/add`, detailModel)
  },

  updateModel(carBrandId: number, modelId: number, detailModel: any) {
    return Http.post(`/admin/brands/${carBrandId}/models/${modelId}/store`, detailModel)
  },

  fetchModelDetails(carModelId: number) {
    return Http.get(`/admin/brands/model/${carModelId}`)
  },

  fetchModelsByBrands(brandId: number) {
    return Http.get(`/brand/${brandId}/models`)
  }

}
