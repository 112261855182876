import { Http } from '@/apis/Http'

export default {

    getLeasingRequests() {
        return Http.get('/leasing-request')
    },

    getListByDealer() {
        return Http.get('/leasing-request/by-dealer')
    },

    getLeasingRequestById(id) {
        return Http.get(`/leasing-request/${id}`)
    },

    sendRequestToLeasing(request) {
        return Http.post('/leasing-request/new', request)
    },

    updateRequestToLeasing(request) {
        return Http.post('/leasing-request/update', request)
    },

    newLeasingRequest() {
        return {
            id: null,
            user_id: 1,
            buyer_type_id: 1,
            subject_type_id: 1,
            company_name: '',
            company_inn: '',
            company_address: '',
            client_name: '',
            car_brand_id: null,
            car_model_id: null,
            price: 0,
            prepaid: 0,
            period: 0,
            comment: '',
            offers: []
        }
    }

}
